import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import LDPContactSaleForm from "~/components/LDP/LDPConTactSaleForm";
import LDPHeader from "~/components/LDP/LDPHeader";
import LDPPartners from "~/components/LDP/LDPPartners";
import LDPSection from "~/components/LDP/LDPSection";
import LDPSitePlan from "~/components/LDP/LDPSitePlan";
import ContactSalesLDP from "~/components/molecules/ContactSalesLDP";
import ContactSalesSticky from "~/components/organisms/ContactSalesSticky";
import FooterLDP from "~/components/organisms/FooterLDP";
import MastheadGalleryLDP from "~/components/organisms/MastheadGalleryLDP";
import SectionProjectDetailsLDP from "~/components/sections/SectionProjectDetailsLDP";
import SectionProjectLocationMapLDP from "~/components/sections/SectionProjectLocationMapLDP";
import SectionProjectMasonryGalleryLDP from "~/components/sections/SectionProjectMasonryGalleryLDP";
import SectionProjectVirtualTourLDP from "~/components/sections/SectionProjectVirtualTourLDP";
import SectionUnitsLDP from "~/components/sections/SectionUnitsLDP";
import { IMediaImageUrls } from "~/entities/media";
import { useIsClient } from "~/hooks/use-is-client";
import useMenuScroll from "~/hooks/use-menu-scroll";
import { THomeLoaderData } from "~/types/homeLoaderData";
import { ELDPOption } from "~/types/landingPage";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import {
  ESectionLDP,
  VARIABLE_COLORS_ROOT,
  VARIABLE_COLORS_SECTIONS_O1,
} from "~/utilities/config/landingPageConfig";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { EMedia } from "~/utilities/enums/Media";
import { Section, SectionIndex } from "~/utilities/enums/ProjectSections";
import scrollToView from "~/utilities/helpers/scrollToView";

export interface SectionsLDPProps {}

export type Ref = HTMLDivElement;
function getRandomItem(array: any[]) {
  if (!array.length) {
    return [];
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}
const SectionsLDP = forwardRef<Ref, SectionsLDPProps>((_, ref) => {
  const {
    config,
    logoHeader,
    topButton,
    userConfig: user,
    ...restConfig
  } = useRouteLoaderData("root") as IRouteLoaderData;
  const {
    projectMenuItems,
    project,
    floorPlansPagination,
    unitTransactions,
    virtualTours,
    saleTransactionFilterOptions,
    floorPlanFilterOptions,
  } = useLoaderData<THomeLoaderData & TProjectLoaderData>();

  const isClient = useIsClient();

  const { t } = useTranslation();

  const validProjectMenuItems = useMemo(() => {
    if (!isClient) return projectMenuItems;

    return projectMenuItems
      ?.filter((item) => !!document.getElementById(item.targetId))
      ?.map((item) => {
        const isExist = !!document.getElementById(item.targetId);
        return {
          ...item,
          disabled: !isExist,
        };
      });
  }, [isClient, projectMenuItems]);

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const { activeIndex, scrollToSection, menuRef } = useMenuScroll({
    sections: sectionRefs.current,
  });

  const imageSources: IMediaImageUrls[] = useMemo(() => {
    const medias = project.medias.filter((media) => {
      return media?.type === EMedia.IMAGE;
    });
    if (project.photo?.type === EMedia.IMAGE) {
      medias.push(project.photo);
    }
    return medias;
  }, [project.photo]);

  return (
    <>
      <main
        style={VARIABLE_COLORS_ROOT[ELDPOption.O1]}
        className="remove-padding flex min-h-screen w-full flex-col items-center bg-[var(--bg-page)]"
        ref={ref}
      >
        {!isEmpty(validProjectMenuItems) && (
          <LDPHeader
            logo={project?.desktopLogo}
            logoMobile={project?.mobileLogo}
            project={project}
            topButton={config?.topButton}
            sections={validProjectMenuItems}
            validProjectMenuItems={validProjectMenuItems}
            projectMenuItems={validProjectMenuItems}
            defaultSection={validProjectMenuItems?.[0]}
            phoneNumber={config?.phoneNumber || ""}
            socialLinks={config?.socialLinks || []}
            top={-1}
          />
        )}
        {(project?.medias?.length > 0 || project?.photo) && (
          <MastheadGalleryLDP
            project={project}
            onShowPriceList={() => {}}
            onShowFlat={() => {}}
            showGradientLayer
            styleRoot={
              VARIABLE_COLORS_SECTIONS_O1[ESectionLDP.LDPMastheadGallery]
            }
          />
        )}

        <SectionProjectDetailsLDP
          ref={(el) =>
            (sectionRefs.current[SectionIndex.PROJECT_DETAILS] = el!)
          }
          id={Section.PROJECT_DETAILS}
          title={projectSectionConfig[Section.PROJECT_DETAILS].title}
          project={project}
          className="ldp"
        />

        <LDPPartners />

        {virtualTours?.length > 0 && (
          <div className="w-full  overflow-hidden">
            <SectionProjectVirtualTourLDP
              // ref={(el) =>
              //   (sectionRefs.current[SectionIndex.VIRTUAL_TOUR] = el!)
              // }
              id={Section.VIRTUAL_TOUR}
              title={projectSectionConfig[Section.VIRTUAL_TOUR].title}
              description={
                projectSectionConfig[Section.VIRTUAL_TOUR].description
              }
              virtualTours={virtualTours}
              project={project}
              unitTypeOptions={project?.unitTypes?.map((unitType) => ({
                id: unitType?.id,
                name: unitType?.title,
              }))}
            />
          </div>
        )}

        {(project?.amenityHtml || project?.googleMapUrl) && (
          <SectionProjectLocationMapLDP
            id={Section.LOCATION_MAP}
            title={projectSectionConfig[Section.LOCATION_MAP].title}
            description={projectSectionConfig[Section.LOCATION_MAP].description}
            project={project}
            className="ldp py-20 lg:py-7"
          />
        )}

        <LDPSection
          styleRoot={VARIABLE_COLORS_SECTIONS_O1[ESectionLDP.LDPUnits]}
        >
          <SectionUnitsLDP
            project={project}
            floorPlansPagination={floorPlansPagination}
            floorPlanFilterOptions={floorPlanFilterOptions}
            unitTransactions={unitTransactions}
            saleTransactionFilterOptions={saleTransactionFilterOptions}
          />
        </LDPSection>

        <LDPSection
          styleRoot={VARIABLE_COLORS_SECTIONS_O1[ESectionLDP.LDPContactSales]}
        >
          <ContactSalesLDP
            id={Section.CONTACT_SALES}
            title={t("contact_sales.heading")}
            user={user}
            projectSlug={project.slug}
          />
        </LDPSection>

        {project?.facilityHtml && (
          <LDPSitePlan
            id={Section.SITE_PLAN}
            title={t(projectSectionConfig[Section.SITE_PLAN].title)}
            description={t(projectSectionConfig[Section.SITE_PLAN].description)}
            className="ldp"
            sitePlanHtml={project.facilityHtml || ""}
            sitePlanImages={
              project?.siteplanImages?.length > 0
                ? project?.siteplanImages
                : project?.medias.filter(
                    (item) => item.type === EMedia.IMAGE
                  ) || []
            }
            styleRoot={VARIABLE_COLORS_SECTIONS_O1[ESectionLDP.LDPSitePlan]}
            imgClassName="rounded-none lg:rounded-none lg:pr-0"
            classNameContainer="[&_.sitePlan-img]:order-1 [&_.sitePlan-img]:pl-0 [&_.sitePlan-content]:order-2 grid-cols-[1fr_400px]"
          />
        )}

        {project?.medias?.length > 0 && (
          <LDPSection
            styleRoot={VARIABLE_COLORS_SECTIONS_O1[ESectionLDP.LDPGallery]}
          >
            <SectionProjectMasonryGalleryLDP
              id={Section.GALLERY}
              title={t(projectSectionConfig[Section.GALLERY].title)}
              project={project}
            />
          </LDPSection>
        )}

        <FooterLDP
          sections={validProjectMenuItems}
          logo={project?.desktopLogo}
          phoneNumber={restConfig?.phoneNumber || ""}
          socialLinks={restConfig?.socialLinks || []}
          siteTitle={config?.siteTitle || ""}
          siteDescription={
            (restConfig?.siteDescription as unknown as string) || ""
          }
          disclaimer={restConfig?.siteDisclaimers?.footerDisclaimer}
          domain={restConfig?.domain || ""}
          locations={restConfig?.projectsByLocations}
          wrapperClass="ldp"
          extraComponent={
            <LDPContactSaleForm
              className="rounded-none"
              style={{
                // @ts-expect-error Background color is set for contact form
                "--bg-contact-form": "#ffffff",
              }}
            />
          }
          className="w-full"
        />
        <ContactSalesSticky
          salePhoneNumber={restConfig?.phoneNumber || user?.phone || ""}
          saleContent={`Hi, I am interested to view ${
            project?.name || ""
          } sales gallery. Thank you`}
          onRegisterClick={() => {
            scrollToView(Section.CONTACT_SALES);
          }}
        />
      </main>
    </>
  );
});

SectionsLDP.displayName = "SectionsLDP";

export default SectionsLDP;
