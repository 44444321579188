import { useLoaderData, useRouteLoaderData } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import LDPContactSaleForm from "~/components/LDP/LDPConTactSaleForm";
import LDPHeader from "~/components/LDP/LDPHeader";
import LDPPartners from "~/components/LDP/LDPPartners";
import LDPSection from "~/components/LDP/LDPSection";
import LDPSitePlan from "~/components/LDP/LDPSitePlan";
import LDPUnits from "~/components/LDP/LDPUnits";
import LDPContactSales from "~/components/LDP/options3/LDPContactSales";
import LDPGallery from "~/components/LDP/options3/LDPGallery";
import LDPMastheadGallery from "~/components/LDP/options3/LDPMastheadGallery";
import LDPProjectDetail from "~/components/LDP/options3/LDPProjectDetail";
import ContactSalesSticky from "~/components/organisms/ContactSalesSticky";
import FooterLDP from "~/components/organisms/FooterLDP";
import SectionProjectLocationMapLDP from "~/components/sections/SectionProjectLocationMapLDP";
import SectionProjectVirtualTourLDP from "~/components/sections/SectionProjectVirtualTourLDP";
import { IMediaImageUrls } from "~/entities/media";
import { useIsClient } from "~/hooks/use-is-client";
import useMenuScroll from "~/hooks/use-menu-scroll";
import { THomeLoaderData } from "~/types/homeLoaderData";
import { ELDPOption } from "~/types/landingPage";
import { TProjectLoaderData } from "~/types/projectLoaderData";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import {
  ESectionLDP,
  VARIABLE_COLORS_ROOT,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { EMedia } from "~/utilities/enums/Media";
import { Section, SectionIndex } from "~/utilities/enums/ProjectSections";
import getRandomItem from "~/utilities/helpers/randomItem";
import scrollToView from "~/utilities/helpers/scrollToView";

export interface LDPOp2Props {}

export type Ref = HTMLDivElement;

const LDPOp3 = forwardRef<Ref, LDPOp2Props>((_, ref) => {
  const {
    config,
    logoHeader,
    topButton,
    userConfig: user,
    ...restConfig
  } = useRouteLoaderData("root") as IRouteLoaderData;
  const {
    projectMenuItems,
    project,
    floorPlansPagination,
    unitTransactions,
    // curatedProjects,
    virtualTours,
    saleTransactionFilterOptions,
    floorPlanFilterOptions,
  } = useLoaderData<THomeLoaderData & TProjectLoaderData>();

  const isClient = useIsClient();

  const { t } = useTranslation();

  const validProjectMenuItems = useMemo(() => {
    if (!isClient) return projectMenuItems;
    return projectMenuItems
      ?.filter((item) => !!document.getElementById(item.targetId))
      ?.map((item) => {
        const isExist = !!document.getElementById(item.targetId);
        return {
          ...item,
          disabled: !isExist,
        };
      });
  }, [isClient, projectMenuItems]);

  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const { activeIndex, scrollToSection, menuRef } = useMenuScroll({
    sections: sectionRefs.current,
  });

  const imageSources: IMediaImageUrls[] = useMemo(() => {
    const medias = project.medias.filter((media) => {
      return media?.type === EMedia.IMAGE;
    });
    if (project.photo?.type === EMedia.IMAGE) {
      medias.push(project.photo);
    }
    return medias;
  }, [project.photo]);

  return (
    <main style={VARIABLE_COLORS_ROOT[ELDPOption.O3]}>
      <div
        className="remove-padding flex min-h-screen w-full flex-col items-center bg-[var(--bg-page)]"
        ref={ref}
      >
        {!isEmpty(validProjectMenuItems) && (
          <>
            <LDPHeader
              logo={project?.desktopLogo}
              logoMobile={project?.mobileLogo}
              project={project}
              topButton={config?.topButton}
              sections={validProjectMenuItems}
              validProjectMenuItems={validProjectMenuItems}
              projectMenuItems={validProjectMenuItems}
              defaultSection={validProjectMenuItems?.[0]}
              phoneNumber={config?.phoneNumber || ""}
              socialLinks={config?.socialLinks || []}
              top={-1}
            />
          </>
        )}
        {(project?.medias?.length > 0 || project?.photo) && (
          <LDPMastheadGallery
            project={project}
            thumbnailImg={getRandomItem(imageSources)}
          />
        )}

        {/* <LDPLatestUpdate
          titleHtml={project.name}
          descriptionHtml="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores minima velit quod illum voluptates, odit sint corrupti deserunt aut ut."
          image={getRandomItem(imageSources)}
        /> */}

        <LDPProjectDetail
          ref={(el) =>
            (sectionRefs.current[SectionIndex.PROJECT_DETAILS] = el!)
          }
          id={Section.PROJECT_DETAILS}
          title={projectSectionConfig[Section.PROJECT_DETAILS].title}
          project={project}
        />

        {/* <LDPProjectVideo
          title="Lorem ipsum"
          descriptionHtml="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea "
          videoMedia={getRandomItem(
            project.medias.filter((item) => item.type === EMedia.VIDEO)
          )}
          thumbnail={getRandomItem(imageSources)}
          partnerLogo={edgeLogo}
        /> */}

        <LDPPartners
          hasBorder={false}
          styleRoot={VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPPartners]}
        />

        {/* <LDPMarketing
          imagesData={imageSources}
          descriptionHtml="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          title="Your Haven Amongst the Bustling City"
        /> */}

        <LDPUnits
          project={project}
          floorPlansPagination={floorPlansPagination}
          floorPlanFilterOptions={floorPlanFilterOptions}
          unitTransactions={unitTransactions}
          saleTransactionFilterOptions={saleTransactionFilterOptions}
          styleRoot={VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPUnits]}
        />

        {(project?.amenityHtml || project?.googleMapUrl) && (
          <LDPSection
            styleRoot={VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPLocation]}
          >
            <SectionProjectLocationMapLDP
              id={Section.LOCATION_MAP}
              title={projectSectionConfig[Section.LOCATION_MAP].title}
              description={
                projectSectionConfig[Section.LOCATION_MAP].description
              }
              project={project}
              className="py-20 lg:py-12"
            />
          </LDPSection>
        )}

        <LDPContactSales
          contactSaleImage={getRandomItem(imageSources)}
          id={Section.CONTACT_SALES}
        />

        {virtualTours?.length > 0 && (
          <LDPSection
            styleRoot={VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPVirtualTour]}
            className="max-w-full px-0"
          >
            <SectionProjectVirtualTourLDP
              // ref={(el) =>
              //   (sectionRefs.current[SectionIndex.VIRTUAL_TOUR] = el!)
              // }
              id={Section.VIRTUAL_TOUR}
              title={projectSectionConfig[Section.VIRTUAL_TOUR].title}
              description={
                projectSectionConfig[Section.VIRTUAL_TOUR].description
              }
              virtualTours={virtualTours}
              project={project}
              unitTypeOptions={project?.unitTypes?.map((unitType) => ({
                id: unitType?.id,
                name: unitType?.title,
              }))}
            />
          </LDPSection>
        )}

        {project?.facilityHtml && (
          <LDPSitePlan
            id={Section.SITE_PLAN}
            title={t(projectSectionConfig[Section.SITE_PLAN].title)}
            description={t(projectSectionConfig[Section.SITE_PLAN].description)}
            className="ldp"
            sitePlanHtml={project.facilityHtml || ""}
            sitePlanImages={
              project?.siteplanImages?.length > 0
                ? project?.siteplanImages
                : project?.medias.filter(
                    (item) => item.type === EMedia.IMAGE
                  ) || []
            }
            styleRoot={VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPSitePlan]}
            imgClassName="rounded-3xl lg:rounded-xl lg:pr-0"
            classNameContainer="[&_.sitePlan-img]:order-1 [&_.sitePlan-img]:pl-0 [&_.sitePlan-content]:order-2 grid-cols-[1fr_400px]"
          />
        )}
        {project?.medias?.length > 0 && (
          <LDPGallery
            id={Section.GALLERY}
            title={t(projectSectionConfig[Section.GALLERY].title)}
            project={project}
            description="Discover more images about the project"
          />
        )}
      </div>
      <FooterLDP
        sections={validProjectMenuItems}
        logo={project?.desktopLogo}
        phoneNumber={restConfig?.phoneNumber || ""}
        socialLinks={restConfig?.socialLinks || []}
        siteTitle={config?.siteTitle || ""}
        siteDescription={
          (restConfig?.siteDescription as unknown as string) || ""
        }
        disclaimer={restConfig?.siteDisclaimers?.footerDisclaimer}
        domain={restConfig?.domain || ""}
        locations={restConfig?.projectsByLocations}
        wrapperClass="ldp"
        extraComponent={
          <LDPContactSaleForm
            style={{
              // @ts-expect-error Background color is set for contact form
              "--bg-contact-form": "#FFFCF5",
            }}
            className="rounded-3xl lg:rounded-xl"
          />
        }
      />

      <ContactSalesSticky
        salePhoneNumber={restConfig?.phoneNumber || user?.phone || ""}
        saleContent={`Hi, I am interested to view ${
          project?.name || ""
        } sales gallery. Thank you`}
        onRegisterClick={() => {
          scrollToView(Section.CONTACT_SALES);
        }}
      />
    </main>
  );
});

LDPOp3.displayName = "LDPOp3";

export default LDPOp3;
